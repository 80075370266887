import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

const UserList = () => import('@/views/admins/UserList')
const User = () => import('@/views/admins/User')

const VehicleList = () => import('@/views/agents/VehicleList')
const Vehicle = () => import('@/views/agents/Vehicle')

const Profile = () => import('@/views/general/Profile')

//
const StockList = () => import('@/views/admins/StockList')
const Stock = () => import('@/views/admins/Stock')

const DisburstmentList = () => import('@/views/admins/DisburstmentList')
const Disburstment = () => import('@/views/admins/Disburstment')

const Company = () => import('@/views/admins/Company')
const CompanyEdit = () => import('@/views/admins/CompanyEdit')

const RoleList = () => import('@/views/admins/RoleList')
const Role = () => import('@/views/admins/Role')


const BrandList = () => import('@/views/admins/BrandList')
const Brand = () => import('@/views/admins/Brand')

const BankList = () => import('@/views/admins/BankList')
const Bank = () => import('@/views/admins/Bank')

const ForwarderList = () => import('@/views/admins/ForwarderList')
const Forwarder = () => import('@/views/admins/Forwarder')

const SupplierList = () => import('@/views/admins/SupplierList')
const Supplier = () => import('@/views/admins/Supplier')


const CustomerList = () => import('@/views/admins/CustomerList')
const Customer = () => import('@/views/admins/Customer')


Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})


router.beforeEach((to, from, next) => {
  var isAuth = auth.check();
  if (!isAuth) {
    if (to.name == 'Login') {
      next();
    }
    else {
      next({ name: 'Login' });
    }
  }
  else {
    next();
  }
})

export default router

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
       
        },
        {
          path: 'agents',
          redirect: '/agents/vehicleList',
          name: 'Agents',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'Vehicle/:id',
              name: 'VehicleById',
              component: Vehicle
            },
            {
              path: 'Vehicle',
              name: 'Vehicle',
              component: Vehicle
            },
            {
              path: 'VehicleList',
              name: 'VehicleList',
              component: VehicleList
            },
          ]
        },
        {
          path: 'admins',
          redirect: '/admins/userList',
          name: 'User',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'User/:id',
              name: 'UserById',
              component: User
            },
            {
              path: 'User',
              name: 'User',
              component:User
            },
            
            {
              path: 'UserList',
              name: 'UserList',
              component: UserList
            },
            
            {
              path: 'role/:id',
              name: 'roleById',
              component: Role
            },
            {
              path: 'role',
              name: 'role',
              component:Role
            },
            {
              path: 'roleList',
              name: 'roleList',
              component: RoleList
            },
            {
              path: 'stock/:id',
              name: 'stockById',
              component: Stock
            },
            {
              path: 'stock',
              name: 'stock',
              component:Stock
            },
            {
              path: 'stockList',
              name: 'stockList',
              component: StockList
            },

            {
              path: 'disburstment/:id',
              name: 'disburstmentById',
              component: Disburstment
            },
            {
              path: 'disburstmentList',
              name: 'disburstmentList',
              component: DisburstmentList
            },
            {
              path: 'companyEdit',
              name: 'CompanyEdit',
              component: CompanyEdit
            },
            {
              path: 'company',
              name: 'company',
              component: Company
            },

            {
              path: 'Brand/:id',
              name: 'BrandById',
              component: Brand
            },
            {
              path: 'Brand',
              name: 'Brand',
              component: Brand
            },
            {
              path: 'BrandList',
              name: 'BrandList',
              component: BrandList
            },

            {
              path: 'Bank/:id',
              name: 'BankById',
              component: Bank
            },
            {
              path: 'Bank',
              name: 'Bank',
              component: Bank
            },
            {
              path: 'BankList',
              name: 'BankList',
              component: BankList
            },

            {
              path: 'Forwarder/:id',
              name: 'ForwarderById',
              component: Forwarder
            },
            {
              path: 'Forwarder',
              name: 'Forwarder',
              component: Forwarder
            },
            {
              path: 'ForwarderList',
              name: 'ForwarderList',
              component: ForwarderList
            },


            {
              path: 'Supplier/:id',
              name: 'SupplierById',
              component: Supplier
            },
            {
              path: 'Supplier',
              name: 'Supplier',
              component: Supplier
            },
            {
              path: 'SupplierList',
              name: 'SupplierList',
              component: SupplierList
            },

            {
              path: 'Customer/:id',
              name: 'CustomerById',
              component: Customer
            },
            {
              path: 'Customer',
              name: 'Customer',
              component: Customer
            },
            {
              path: 'CustomerList',
              name: 'CustomerList',
              component: CustomerList
            },


          ]
        },
        {
          path: 'general',
          redirect: '/general/profile',
          name: 'Profile',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'profile',
              name: 'profile',
              component: Profile
            },


          ]
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}
