export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cilChartPie',
        moduleName: 'Dashboard' // This will be used for filtering
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Operation'],
        moduleName: 'Dashboard' // This will be used for filtering
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Stock',
        to: '/admins/StockList',
        icon: 'cilTask',
        moduleName: 'Stocks' // This will be used for filtering
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Customer',
        to: '/admins/CustomerList',
        icon: 'cilUser',
        moduleName: 'Customer' // This will be used for filtering
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Vehicle',
        to: '/agents/VehicleList',
        icon: 'cilListRich',
        moduleName: 'Vehicle' // This will be used for filtering
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'MasterData',
        route: '/parameters',
        icon: 'cil-layers',
        moduleName: 'MasterData', // This will be used for filtering
        _children: [
          {
            _name: 'CSidebarNavItem',
            name: 'Suppliers',
            to: '/admins/SupplierList',
            icon: 'cilBasket',
            subModuleName: 'Suppliers' // This will be used for filtering
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Forwarder',
            to: '/admins/ForwarderList',
            icon: 'cilChevronRight',
            subModuleName: 'Forwarder' // This will be used for filtering
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Banks',
            to: '/admins/BankList',
            icon: 'cilDollar',
            subModuleName: 'Banks' // This will be used for filtering
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Brands',
            to: '/admins/BrandList',
            icon: 'cilNotes',
            subModuleName: 'Brands' // This will be used for filtering
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Letters',
        route: '/disburstment',
        icon: 'cilPencil',
        moduleName: 'Letters', // This will be used for filtering
        _children: [
          {
            _name: 'CSidebarNavItem',
            name: 'Disbursement',
            to: '/admins/disburstmentList',
            icon: 'cil-spreadsheet',
            moduleName: 'Letters' // This will be used for filtering
          }
        ]
      },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Reports',
      //   route: '/disburstment',
      //   icon: 'cil-layers',
      //   moduleName: 'Reports', // This will be used for filtering
      //   _children: [
      //     {
      //       _name: 'CSidebarNavItem',
      //       name: 'SaleByMonthly',
      //       to: '/admins/disburstmentList',
      //       icon: 'cil-spreadsheet',
      //       subModuleName: 'SaleByMonthly' // This will be used for filtering
      //     }
      //   ]
      // },

      {
        _name: 'CSidebarNavTitle',
        _children: ['Administration'],
        moduleName: 'Administration', // This will be used for filtering
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Roles',
        to: '/admins/RoleList',
        icon: 'cilShieldAlt',
        subModuleName: 'Roles' // This will be used for filtering
      },
      {
        _name: 'CSidebarNavItem',
        name: 'User',
        to: '/admins/UserList',
        icon: 'cilPeople',
        subModuleName: 'User' // This will be used for filtering
      },

      {
        _name: 'CSidebarNavTitle',
        _children: ['System'],
        moduleName: 'System', // This will be used for filtering
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Company',
        to: '/admins/Company',
        icon: 'cilGlobeAlt',
        subModuleName: 'Company' // This will be used for filtering
      },


    ]
  }
];
