// main.js
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import CoreuiVuePro from '@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'
import AuthService from './lib/authservice'
import Api from './lib/api'
import Vuelidate from 'vuelidate'
import Helper from './lib/helper'
import menu from './store/menu'
// import { filterMenuItems } from './utils/filterMenu'

Vue.use(Vuelidate)
Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console)

window.api = new Api()
window.auth = new AuthService()
window.helper = new Helper()
// window.apiUrl = window.config.API_URL;
window.apiUrl = process.env.VUE_APP_API_URL || window.config.API_URL;

// console.log(store.getters.isAuthenticated);
// if (store.getters.isAuthenticated) {
//   store.dispatch('fetchUserRoles').then(() => {
//     const userRoles = store.getters.roles
//     const filteredMenu = filterMenuItems(menu, userRoles)
//     Vue.prototype.$menu = filteredMenu
//   })
// } else {
//   Vue.prototype.$menu = menu
// }

Vue.prototype.$menu = menu

new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
