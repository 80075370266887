import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { filterMenuByName } from '../utils/filterMenu'; // Assume you've updated this utility function
import menuConfig from './menu'; // Assume this is your menu configuration

Vue.use(Vuex);

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  roles: [],
  roleModulePermissions: [],
  roleSubModulePermissions: [],
  menu: [],
  isAuthenticated: false,
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setRoleModulePermissions(state, permissions) {
    state.roleModulePermissions = permissions;
  },
  setRoleSubModulePermissions(state, permissions) {
    state.roleSubModulePermissions = permissions;
  },
  setMenu(state, menu) {
    state.menu = menu;
  },
  setAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
};

const actions = {
  login({ commit }, { roles, roleModulePermissions, roleSubModulePermissions }) {
    commit('setAuthenticated', true);
    commit('setRoles', roles);
    commit('setRoleModulePermissions', roleModulePermissions);
    commit('setRoleSubModulePermissions', roleSubModulePermissions);
    // Update the menu after login
    // this.dispatch('updateMenu');
  },
  logout({ commit }) {
    commit('setAuthenticated', false);
    commit('setRoles', []);
    commit('setRoleModulePermissions', []);
    commit('setRoleSubModulePermissions', []);
    commit('setMenu', []);
  },
  updateMenu({ commit, state }) {
    // Filter menu based on roleModulePermissions and roleSubModulePermissions
    const filteredMenu = filterMenuByName(menuConfig, state.roleModulePermissions, state.roleSubModulePermissions);
    commit('setMenu', filteredMenu);
  },
};

const getters = {
  roles: state => state.roles,
  roleModulePermissions: state => state.roleModulePermissions,
  roleSubModulePermissions: state => state.roleSubModulePermissions,
  menu: state => state.menu,
  isAuthenticated: state => state.isAuthenticated,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [createPersistedState()], // Persist Vuex state across page reloads
});
