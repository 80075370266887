import axios from 'axios'
import store from '../store'
import { filterMenuByName } from '../utils/filterMenu'
import menu from '../store/menu'

class AuthService {
    constructor() {
        this.token = window.localStorage.getItem('token');
        let userData = window.localStorage.getItem('user');
        if (userData !== 'undefined') {
            this.user = userData ? JSON.parse(userData) : null;
            if (this.token !== null) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
                axios.defaults.headers.common['api-token'] = this.token;
            }
        }
    }

    getUser() {
        api.call('get', apiUrl + 'Account/get-user')
            .then(({ data }) => {
                this.user = data;
            });
    }
    getUserId() {
        this.user.id;
    }

    doLogin(data) {
        var url = apiUrl + 'Account/login';
        return api.call('post', url, data)
            .then((response) => {
                console.log(response.data.result);
                return response.data
            });

    }

    register(data) {
        var url = apiUrl + 'Account/Register';
        return api.call('post', url, data)
            .then((response) => {
                return response.data
            });

    }

    recordLogin(token, user) {
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', JSON.stringify(user));

        console.log(user);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.token = token;
        this.user = user;
        var roles = this.user.roles;
        var roleModulePermissions = this.user.roleModulePermissions;
        var roleSubModulePermissions = this.user.roleSubModulePermissions;
        var modules = this.user.modules;

        // const { token, displayName, roleModulePermissions, roleSubModulePermissions } = response.data;

        // Store the token, user, and permissions in Vuex
        store.dispatch('login', {
        //   token: token,
          roles: [user.role], // Adjust if necessary
          roleModulePermissions,
          roleSubModulePermissions,
        });


        // console.log(roles);
        // console.log(permissions);
        // console.log(modules);
        // const permissionsUpdate = permissions.reduce((acc, perm) => {
        //     acc[perm.module] = {
        //       canAdd: perm.canAdd,
        //       canUpdate: perm.canUpdate,
        //       canDelete: perm.canDelete,
        //       canView: perm.canView
        //     }
        //     return acc;
        //   }, {})
        //   console.log(permissionsUpdate);

        // store.dispatch('login', { roles: roles, permissions: permissions })

        // const filteredMenu = filterMenuItems(menu, permissions)
        const filteredMenu = filterMenuByName(menu,  roleModulePermissions, roleSubModulePermissions);
        console.log(filteredMenu);
        store.commit('setMenu', filteredMenu)

        // store.dispatch('login', { roles: [this.user.role] })
        // store.dispatch('fetchRolesPermissionsModules') // Fetch roles after login

    }

    isSuperAdmin() {
        return this.user.isSuperAdmin;
    }

    getUserName() {
        return this.user.userName;
    }

    getRole() {
        return this.user.role;
    }

    logout() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        if (this.token) {
            api.call('post', apiUrl + 'Account/logout')
                .then(({ data }) => {
                    // EventBus.$emit('userLoggedOut');
                })
            // EventBus.$emit('userLoggedOut');
        };
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        this.token = null;
        this.user = null;
        store.dispatch('logout')
    }

    check() {
        return !!this.token;
    }


}

export default AuthService;