import axios from 'axios'

class Api {
    constructor() {
        this.token = window.localStorage.getItem('token');
        let userData = window.localStorage.getItem('user');
        if (userData !== 'undefined') {
            this.user = userData ? JSON.parse(userData) : null;
            if (this.token !== null) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
                axios.defaults.headers.common['api-token'] = this.token;
            }
        }
    }

    call(requestType, url, data = null) {
        return new Promise((resolve, reject) => {
            axios[requestType](url, data)
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response) {
                        if(error.response.status === 401)
                        {
                            auth.logout();
                        }
                        reject(error.response);
                    } else if (error.request) {
                        reject(error.request);
                    } else {
                        reject(error.message);
                    }
                   
                });
        });
    }
}

export default Api;